<template>
    <div class="grid crud-demo">
        <div class="col-12">
            <div class="card">
                <Toast/>
                <Toolbar class="mb-4">
                    <template v-slot:start>
                        <div class="my-2">
                            <Button label="Neu" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
                            <Button label="Löschen" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedTemplates || !selectedTemplates.length" />
                        </div>
                    </template>

                    <template v-slot:end>
                        <Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
                    </template>
                </Toolbar>

                <DataTable  ref="dt" :value="template" v-model:selection="selectedTemplates" dataKey="ID" :paginator="true" :rows="10" :filters="filters"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                            currentPageReportTemplate="{first} bis {last} von {totalRecords} Produkttemplate" responsiveLayout="scroll">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Templates</h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Suchen..." />
                            </span>
                        </div>
                    </template>

                    <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                    <Column field="ID" header="ID" :sortable="true">
                        <template #body="slotProps">
                            <span class="p-column-title">ID</span>
                            {{slotProps.data.ID}}
                        </template>
                    </Column>
                    <Column field="NAME" header="Bezeichnung" :sortable="true">
                        <template #body="slotProps">
                            <span class="p-column-title">Bezeichnung</span>
                            {{slotProps.data.NAME}}
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="edittemplate(slotProps.data)" />
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeletetemplate(slotProps.data)" />
                        </template>
                    </Column>
                </DataTable>

                <Dialog  v-model:visible="templateDialog" :style="{width: '950px'}" header="Template Details" :modal="true" class="p-fluid">
                    <div class="field">
                        <label for="NAME">Bezeichnung</label>
                        <InputText id="name" v-model.trim="templateObj.NAME" required="true" autofocus :class="{'p-invalid': submitted && !templateObj.NAME}" />
                        <small class="p-invalid" v-if="submitted && !templateObj.NAME">Bezeichnung muss angegeben werden.</small>
                    </div>
                    <div class="field">
                        <label>Aktionen</label>
                        <MultiSelect v-model="selectedTemplateAktionen" :options="aktionen" optionLabel="bezeichnung" placeholder="Aktionen" :filter="true" class="multiselect-custom">
                        </MultiSelect>
                    </div>
                    <template #footer>
                        <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
                        <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="savetemplate" />
                    </template>
                </Dialog>

                <Dialog v-model:visible="deleteTemplateDialog" :style="{width: '450px'}" header="Achtung!" :modal="true">
                    <div class="flex align-items-center justify-content-center">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                        <span v-if="templateObj"><b>{{templateObj.GRUPPE}}</b> wirklich löschen?</span>
                    </div>
                    <template #footer>
                        <Button label="Nein" icon="pi pi-times" class="p-button-text" @click="deleteTemplateDialog = false"/>
                        <Button label="Ja" icon="pi pi-check" class="p-button-text" @click="deletetemplate" />
                    </template>
                </Dialog>

                <Dialog v-model:visible="deletetemplateDialog" :style="{width: '450px'}" header="Achtung!" :modal="true">
                    <div class="flex align-items-center justify-content-center">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                        <span v-if="templateObj">Ausgewählte Produkttemplate wirklich löschen?</span>
                    </div>
                    <template #footer>
                        <Button label="Nein" icon="pi pi-times" class="p-button-text" @click="deletetemplateDialog = false"/>
                        <Button label="Ja" icon="pi pi-check" class="p-button-text" @click="deleteselectedTemplates" />
                    </template>
                </Dialog>
            </div>
        </div>
    </div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import DataService from "@/service/DataService";

export default {
    data() {
        return {
            template: null,
            templateDialog: false,
            deleteTemplateDialog: false,
            deletetemplateDialog: false,
            neu: false,
            templateObj: {},
            templateAktionenObj: {},
            selectedTemplates: null,
            selectedTemplateAktionen: null,
            aktionen: null,
            filters: {},
            submitted: false,
        }
    },
    dataService: null,
    created() {
        this.dataService = new DataService();
        this.initFilters();
    },
    async mounted() {
        this.template = await this.dataService.getTemplates();
        this.aktionen = await this.dataService.getAktionen();
        // this.selectedTemplateAktionen = this.aktionen;

    },
    methods: {
        openNew() {
            this.templateObj = {};
            this.selectedTemplateAktionen=[]
            this.submitted = false;
            this.templateDialog = true;
            this.neu = true;
        },
        hideDialog() {
            this.templateDialog = false;
            this.submitted = false;
            this.neu = false;
        },
        async savetemplate(){
            this.submitted = true;

            if(this.neu){
                let templateId = await this.dataService.saveTemplate(this.templateObj.NAME);

                this.selectedTemplateAktionen.forEach( (el) => {
                    this.templateAktionenObj.FK_ID_TEMPLATE = templateId[0].ID
                    this.templateAktionenObj.FK_ID_AKTION = el.ID
                    this.dataService.saveAktionTemplates(this.templateAktionenObj);
                    this.templateAktionenObj = {};
                })

                this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Template erstellt', life: 3000});
                setTimeout(async()=> {this.template = await this.dataService.getTemplates()}, 100);
                this.templateDialog = false;
                this.templateObj = {};
            } else {
                await this.dataService.updateTemplate(this.templateObj);
                await this.dataService.deleteAktionTemplates(this.templateObj.ID);

                this.selectedTemplateAktionen.forEach( (el) => {
                    this.templateAktionenObj.FK_ID_TEMPLATE = this.templateObj.ID
                    this.templateAktionenObj.FK_ID_AKTION = el.ID
                    this.dataService.updateAktionTemplates(this.templateAktionenObj);
                    this.templateAktionenObj = {};
                })

                this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Template aktualisiert', life: 3000});
                setTimeout(async()=> {this.template = await this.dataService.getTemplates()}, 100);
                this.templateDialog = false;
                this.templateObj = {};
            }
            this.neu = false;

        },
        async edittemplate(template) {
            this.templateObj = {...template};
            console.log(this.templateObj)
            this.templateDialog = true;
            this.selectedTemplateAktionen = await this.dataService.getAktionTemplates(this.templateObj.ID);
            // console.log(this.selectedTemplateAktionen);
            this.neu = false;
        },
        confirmDeletetemplate(template) {
            this.templateObj = template;
            this.deleteTemplateDialog = true;
        },
        async deletetemplate() {
            let data = [];
            data.push(this.templateObj);
            await this.dataService.deleteTemplates(data);

            this.deleteTemplateDialog = false;
            this.templateObj = {};
            this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Template gelöscht', life: 3000});
            setTimeout(async()=> {this.template = await this.dataService.getTemplates()}, 100);

        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        confirmDeleteSelected() {
            this.deletetemplateDialog = true;
        },
        async deleteselectedTemplates() {
            this.deletetemplateDialog = false;
            await this.dataService.deleteTemplates(this.selectedTemplates);
            this.selectedTemplates = null;
            this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Template gelöscht', life: 3000});
            setTimeout(async()=> {this.template = await this.dataService.getTemplates()}, 100);

        },
        initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import '../assets/demo/styles/badges.scss';
</style>
